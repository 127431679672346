import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '045-777-6175',
    Fax: '',
    PostCode: '226-0016',
    Email: 'info@mantee.co.jp',
    Address: '神奈川県横浜市緑区霧が丘１丁目１７番地１２',
    Building: '',
    CompanyName: '株式会社MANTEE',
    CompanyNo: 'MANTEE Inc.',
    Abbreviation: '（略称：MANTEE）',
    Representative: '孫　林',
    Katakana: 'ソン　リン',
    Capital: '500',
    Banks: '',
    CreateDate: '2023年04月21日',
    YearSell: '',
    HakenNo: '',
    CTO: '',
    // <iframe src="
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.4798206371815!2d139.50569321256074!3d35.51713863885444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f9e72435e0bd%3A0x389221aefa1c9abc!2z44OE44Kv44Kk5qiq5rWc6Zyn44GM5LiY77yI44Kw44Or44O844OX44Ob44O844Og77yP5Zyo5a6F5LuL6K2344K144O844OT44K577yJ!5e0!3m2!1sja!2sjp!4v1684648374199!5m2!1sja!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});