<template>
<div class="breadcrumb-area breadcrumb-area-info5 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Our History</h4>
                        <h1 class="mt-3">MANTEEの沿革</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <section class="history-one">
            <div class="container">
                <div class="block-title text-center">
                    <p class="color-2"><span>Our History</span></p>
                </div>
                <!-- /.block-title text-center -->
                <div class="history-one__row-wrap">
                    <div class="history-one__row-circle"></div>
                    <!-- /.history-one__row-circle -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInLeft" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInLeft;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-1.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>株式会社MANTEE設立</h3>
                                    <p>神奈川県横浜市緑区にMANTEE設立、資本金500万円、オフショア開発開始。
                                    </p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2023年04月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInRight" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInRight;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-2.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>ソリューション事業部を設立</h3>
                                    <p>オフショア開発開始</p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2023年05月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                    <!-- /.row -->
                </div>
                <!-- /.history-one__row-wrap -->
            </div>
            <!-- /.container -->
        </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>

<style scoped>

</style>
