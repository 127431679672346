<template>
  <div class="breadcrumb-area breadcrumb-area-info2 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Contact</h4>
                        <h1 class="mt-3">お問い合わせ</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <!-- Start reinfo_contact section -->
  <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px">
    <div class="container">
        <component :is="currentTab" class="cantact_box_margin_top"></component>
      </div>
  </section>
  <!-- End reinfo_contact section -->
</template>

<script>
import {onMounted, ref} from "vue";
import MailFrom from "../components/MailFrom";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Contact",
  components:{ MailFrom,BreadCrumb},
  setup(){
    const currentTab = ref('MailFrom')
    onMounted(() => {
      document.title = 'お問い合わせ - MANTEE';
    })
    return {currentTab}
  },
}
</script>
